<template>
<div id="idconvertor">
  <b-field horizontal label="选择转换方式">
    <p class="has-text-weight-bold">{{ convertTip }}</p>
    <button class="button is-primary" @click="onSwitch">切换</button>
  </b-field>
  <b-field horizontal label="选择转换方式">
    <b-input :placeholder="inputPlaceholder" v-model="uid" />
  </b-field>
  <b-field horizontal label="转换结果">
    <b-input v-model="to" disabled />
  </b-field>
  <b-field position="is-centered" grouped>
    <button class="button is-success" @click="onSubmit">转换</button>
  </b-field>
  <b-field horizontal label="Login Token">
    <b-input placeholder="输入 Login Token" v-model="loginToken" />
  </b-field>
  <b-field horizontal label="解密结果">
    <b-input v-model="decodeToken" disabled />
  </b-field>
  <b-field position="is-centered" grouped>
    <button class="button is-success" @click="onDecode">解密</button>
  </b-field>
</div>
</template>

<script>
// ID 转换功能

export default {
  name: 'idconvertor',
  computed: {
    inputPlaceholder () {
      if (this.id2ic) return '输入 SAGIUID(LID)'
      return '输入 INVITECODE(游戏中显示ID)'
    },
    convertTip () {
      if (this.id2ic) return 'SAGIUID(LID) => INVITECODE(游戏中显示ID)'
      return 'INVITECODE(游戏中显示ID) => SAGIUID(LID)'
    }
  },
  data: function () {
    return {
      id2ic: true,
      uid: null,
      to: null,
      loginToken: null,
      decodeToken: null
    }
  },
  methods: {
    onSwitch () {
      this.uid = null
      this.to = null
      this.id2ic = !this.id2ic
      this.loginToken = null
      this.decodeToken = null
    },
    async onSubmit () {
      try {
        const rdata = await this.mjp.get('/util/id/convert/', { uid: this.uid, to: this.id2ic ? 'ic' : 'id' })
        this.to = rdata.id
      } catch (err) {
        console.error(err)
      }
    },
    async onDecode () {
      try {
        const rdata = await this.mjp.post('/rsi/regional/decodelogintoken/', { logintoken: this.loginToken })
        this.decodeToken = JSON.stringify(rdata.token)
      } catch (err) {
        console.error(err)
        this.decodeToken = null
      }
    }
  }
}
</script>
